<template>
	<div id="app">
		<router-view v-if="isRouterAlive" />
	</div>
</template>

<script>
	export default {
		provide(){
			return{
				reload: this.reloadMethod,
			}
		},
		data() {
			return {
				isRouterAlive: true,
			}
		},
		methods: {
			reloadMethod(){    //通过声明reloadMethod方法，控制router-view的显示或隐藏，从而控制页面的再次加载
				this.isRouterAlive = false;
				this.$nextTick(function(){
					this.isRouterAlive = true;
				});
			},
		},
		created(){

		},
		mounted(){

		},
		watch:{

		}
	}
</script>

<style>
	/* @import url("assets/css/custom.css"); */
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		height: 100%;
		width: 100%;
	}
</style>
