import Vue from 'vue'
import Vuex from 'vuex'
import menus from "./modules/menus.js"
import stock from "./modules/stock.js"

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		menus,
		stock
	}
})
