import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
	state: {
		menu: {
			title: '贷款计算器',
			index: '1',
			addr: '/'
		}
	},
	mutations: {
		setMenu(state, menu){
			localStorage.setItem("menu", JSON.stringify(menu));
			state.menu = menu;
		},
	},
	actions: {},
	getters: {
		getMenu(state){
			if(localStorage.getItem("menu")!=null){
				state.menu = JSON.parse(localStorage.getItem("menu"));
			}
			return state.menu;
		},
	}
}