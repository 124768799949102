import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import Loans from '@/views/loans/Index.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	component: Index,
	children: [ //通过children配置子级路由
		{
			path: '', //生成随机密码生成
			component:  () => import('@/views/Random.vue')
		},
		{
			path: '/invest',//利息计算器
			component:   () => import('@/views/Invest/Index.vue')
		},
		{
			path: '/stock',	//成本计算器
			component: () => import('@/views/Stock.vue')
		},
		{
			path: '/changecode',//编码转换
			component:  () => import('@/views/ChangeCode.vue')
		},
		{
			path: '/pdftoword',//PDF转Word
			component:  () => import('@/views/PdfToWrod.vue')
		},
		{
			path: '/stockcost',//成本计算器
			component:  () => import('@/views/StockCost.vue')
		},
	],
}]

const router = new VueRouter({
	mode: 'history', //改为history模式  hash
	routes
})

export default router
