<template>
    <el-drawer
            :visible.sync="drawer"
            size="74%"
            :with-header="false">
        <p class="title">便民工具箱</p>
        <p><a href="#" class="menu-item">随机密码生成</a></p>
        <p><a href="/invest/" class="menu-item">利息计算器</a></p>
        <p><a href="/stockcost/" class="menu-item">成本计算器</a></p>
        <p><a href="/changecode/" class="menu-item">编码转换</a></p>
    </el-drawer>
</template>

<script>
export default {
    name: "MobileHeader",
    data() {
        return {
            drawer: false
        }
    },
    methods: {
        show() {
            this.drawer = true
        },
    }
}
</script>

<style scoped>
    .title{
        text-align: left;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
        margin-left: 2rem;
        margin-top: 1rem;
    }
    .menu-item{
        text-align: left;
        font-size: 1rem;
        color: #333;
        display: block;
        margin-bottom: 0.5rem;
        margin-left: 2rem;
        color: initial;
    }
</style>
