<template>
	<div class="loans">
		<table>
			<tr id="loanMode">
				<td class="td1"><span>贷款方式</span></td>
				<td class="td2" colspan="2">
					<el-radio-group v-model="loanMode" @change="switchLoanMethod()" size="mini">
						<el-radio-button label="商业/公积金贷款"></el-radio-button>
						<el-radio-button label="组合贷款"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanGJJMoney">
				<td class="td1"><span>公积金贷款金额</span></td>
				<td class="td2">
					<el-input v-model.number="loanGJJMoney" @input="onInput()" placeholder="请输入" maxlength="5" size="small"></el-input>
				</td>
				<td class="td3">
					<el-radio-group v-model="loanGJJMoneyUnit" size="mini" @change="onInput()">
						<el-radio-button label="万"></el-radio-button>
						<el-radio-button label="元"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanGJJRate">
				<td class="td1"><span>公积金贷款利率(%)</span></td>
				<td class="td2">
					<el-input v-model="loanGJJRate" @input="onInput()" placeholder="请输入" maxlength="6" size="small"></el-input>
				</td>
				<td class="td3">
					<el-radio-group v-model="loanGJJRateUnit" size="mini" @change="onInput()">
						<el-radio-button label="年"></el-radio-button>
						<el-radio-button label="月"></el-radio-button>
						<el-radio-button label="日"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanMoney">
				<td class="td1"><span>贷款金额</span></td>
				<td class="td2">
					<el-input v-model.number="loanMoney" @input="onInput()" placeholder="请输入" maxlength="5" size="small"></el-input>
				</td>
				<td class="td3">
					<el-radio-group v-model="loanMoneyUnit" size="mini" @change="onInput()">
						<el-radio-button label="万"></el-radio-button>
						<el-radio-button label="元"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanRate">
				<td class="td1"><span>贷款利率(%)</span></td>
				<td class="td2">
					<el-input v-model="loanRate" @input="onInput()" placeholder="请输入" maxlength="6" size="small"></el-input>
				</td>
				<td class="td3">
					<el-radio-group v-model="loanRateUnit" size="mini" @change="onInput()">
						<el-radio-button label="年"></el-radio-button>
						<el-radio-button label="月"></el-radio-button>
						<el-radio-button label="日"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanTimeLimit">
				<td class="td1"><span>贷款期限</span></td>
				<td class="td2">
					<el-input v-model.number="loanTimeLimit" @input="onInput()" placeholder="请输入" maxlength="2" size="small"></el-input>
				</td>
				<td class="td3">
					<el-radio-group v-model="loanTimeUnit" size="mini" @change="onInput()">
						<el-radio-button label="年"></el-radio-button>
						<el-radio-button label="月"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanRepayMode">
				<td class="td1"><span>还款方式</span></td>
				<td class="td2" colspan="2">
					<el-radio-group v-model="loanRepayMode" @change="switchRepayMode()" size="mini">
						<el-radio-button label="等额本息"></el-radio-button>
						<el-radio-button label="等额本金"></el-radio-button>
					</el-radio-group>
				</td>
			</tr>
			<tr id="loanMonthlyPay">
				<td class="td1"><span>每月月供(元)</span></td>
				<td class="td2" colspan="2">{{loanMonthlyPay.toFixed(2)}}</td>
			</tr>
			<tr id="loanFirstPay">
				<td class="td1"><span>首月月供(元)</span></td>
				<td class="td2" colspan="2">{{linear}}</td>
			</tr>
			<tr id="loanTotalInterest">
				<td class="td1"><span>累计利息(元)</span></td>
				<td class="td2" colspan="2">{{loanTotalInterest.toFixed(2)}}</td>
			</tr>
			<tr id="loanTotalMoney">
				<td class="td1"><span>累计还款(元)</span></td>
				<td class="td2" colspan="2">{{loanTotalMoney.toFixed(2)}}</td>
			</tr>
		</table>

		<el-table :data="loanTableData" style="width: 98%">
			<el-table-column prop="month" label="期数"> </el-table-column>
			<el-table-column prop="monthlyPay" label="月供(元)"> </el-table-column>
			<el-table-column prop="money" label="本金(元)"> </el-table-column>
			<el-table-column prop="interest" label="利息(元)"> </el-table-column>
		</el-table>
	</div>
</template>

<script>
	import Table from "@/views/Test.vue";

	export default {
		components: {Table},
		metaInfo: {
			title: '贷款计算器 - 免费在线贷款利率和还款计算',
			meta: [
				{
					charset: "utf-8"
				},
				{
					name: 'description,',
					content: '使用我们的免费在线贷款计算器,轻松计算商业贷款、公积金贷款或组合贷款的月供、总利息和还款金额。立即获取您的个性化贷款方案!'
				},
				{
					name: 'summary,',
					content: '使用我们的免费在线贷款计算器,轻松计算商业贷款、公积金贷款或组合贷款的月供、总利息和还款金额。立即获取您的个性化贷款方案!'
				},
				{
					name: 'keywords',
					content: '贷款计算器,贷款利率计算,贷款还款计算,商业贷款,公积金贷款,组合贷款'
				}
			],
			link: [
				{
					rel: 'url',
					href: 'https://www.itptg.com/'
				}
			]
		},
		data() {
			return {
				loanMode: '商业/公积金贷款',
				loanGJJMoney: 0,
				loanGJJMoneyUnit: '万',
				loanGJJRate: 3.25,
				loanGJJRateUnit: '年',
				loanMoney: 0,
				loanMoneyUnit: '万',
				loanTimeLimit: 0,
				loanTimeUnit: '年',
				loanRate: 4.9,
				loanRateUnit: '年',
				loanRepayMode: '等额本息',
				loanMonthlyPay: 0,
				loanTotalInterest: 0,
				loanTotalMoney: 0,
				loanTableData: [],
			}
		},
		computed: {
			linear() { //等额本金
				if (this.loanMoney > 0 && this.loanTimeLimit > 0 && this.loanRate > 0) {
					let conver = this.conversion;
					let p = conver.p; //贷款本金
					let r = conver.r; //月利率
					let n = conver.n; //还款期数
					let firstMonthPay = 0; //首月月供

					if (this.loanMode == "商业/公积金贷款") {
						firstMonthPay = p / n + (p - 0) * r;
						return firstMonthPay.toFixed(2);
					} else if (this.loanMode == "组合贷款") {
						if (this.loanGJJMoney > 0 && this.loanGJJRate > 0) {
							let gjjp = conver.gjjp; //公积金贷款本金
							let gjjr = conver.gjjr; //公积金月利率

							let gjjFirstMonthPay = gjjp / n + (gjjp - 0) * gjjr;
							firstMonthPay = p / n + (p - 0) * r;
							return (gjjFirstMonthPay + firstMonthPay).toFixed(2);
						} else {
							return 0;
						}
					}
				} else {
					return 0;
				}
			},
			conversion() {
				let gjjp, gjjr, p, n, r;
				if (this.loanMoneyUnit == '万') {
					p = this.loanMoney * 10000; //贷款本金
				} else if (this.loanMoneyUnit == '元') {
					p = this.loanMoney; //贷款本金
				}
				if (this.loanRateUnit == '年') {
					r = this.loanRate / 12 * 0.01; //年利率
				} else if (this.loanRateUnit == '月') {
					r = this.loanRate * 0.01; //月利率
				} else if (this.loanRateUnit == '日') {
					r = this.loanRate * 30 * 0.01; //日利率
				}
				if (this.loanGJJMoneyUnit == '万') {
					gjjp = this.loanGJJMoney * 10000; //贷款本金
				} else if (this.loanGJJMoneyUnit == '元') {
					gjjp = this.loanGJJMoney; //贷款本金
				}
				if (this.loanGJJRateUnit == '年') {
					gjjr = this.loanGJJRate / 12 * 0.01; //年利率
				} else if (this.loanGJJRateUnit == '月') {
					gjjr = this.loanGJJRate * 0.01; //月利率
				} else if (this.loanGJJRateUnit == '日') {
					gjjr = this.loanGJJRate * 30 * 0.01; //日利率
				}
				if (this.loanTimeUnit == '年') {
					n = this.loanTimeLimit * 12; //还款期数
				} else if (this.loanTimeUnit == '月') {
					n = this.loanTimeLimit; //还款期数
				}
				return {gjjp,gjjr,p,n,r}
			},
		},
		methods: {
			annuity(getGJJ = false) { //等额本息
				if (this.loanMoney > 0 && this.loanTimeLimit > 0 && this.loanRate > 0) {
					let conver = this.conversion;
					let p = conver.p; //贷款本金
					let r = conver.r; //月利率
					let n = conver.n; //还款期数
					let monthlyPay = 0; //每月月供

					if (this.loanMode == "商业/公积金贷款") {
						monthlyPay = (p * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
						return monthlyPay;
					} else if (this.loanMode == "组合贷款") {
						if (this.loanGJJMoney > 0 && this.loanGJJRate > 0) {

							if (getGJJ) {
								let gjjp = conver.gjjp; //公积金贷款本金
								let gjjr = conver.gjjr; //公积金月利率

								let gjjMonthlyPay = (gjjp * gjjr * Math.pow(1 + gjjr, n)) / (Math.pow(1 + gjjr, n) - 1);
								return gjjMonthlyPay;
							} else {
								monthlyPay = (p * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
								return monthlyPay;
							}
						} else {
							return 0;
						}
					}
				} else {
					return 0;
				}
			},
			onInput() {
				this.loanMonthlyPay = 0;
				this.loanTotalInterest = 0;
				this.loanTotalMoney = 0;
				this.loanTableData = [];

				if (this.loanMoney > 0 && this.loanRate > 0 && this.loanTimeLimit > 0) {
					let conver = this.conversion;
					let p = conver.p; //贷款本金
					let r = conver.r; //月利率
					let n = conver.n; //还款期数
					let monthlyPayInterest = 0; //每月还款利息
					let monthlyPayMoney = 0; //每月还款本金

					if (this.loanMode == "商业/公积金贷款") {
						if (this.loanRepayMode == "等额本金") {
							let monthlyPayTotal = 0; //每月月供(还本付息)

							for (let i = 0; i < n; i++) {
								monthlyPayMoney = p / n; //每月还款本金= 总本金 / 还款月数
								monthlyPayInterest = (p - monthlyPayMoney * i) * r; //每月还款利息=（本金 - 累计已还本金）× 月利率
								monthlyPayTotal = monthlyPayMoney + monthlyPayInterest; //月供 = 每月还款本金 + 每月还款利息
								this.loanTotalInterest += monthlyPayInterest; //累计利息
								this.loanTotalMoney += monthlyPayTotal; //累计还款金额
								let list = {
									month: '第' + (i + 1) + '期',
									monthlyPay: monthlyPayTotal.toFixed(2),
									money: monthlyPayMoney.toFixed(2),
									interest: monthlyPayInterest.toFixed(2),
								}
								this.loanTableData.push(list);
							}
						} else if (this.loanRepayMode == "等额本息") {
							this.loanMonthlyPay = this.annuity();

							for (let i = 0; i < n; i++) {
								monthlyPayInterest = (p - (this.annuity() * i - this.loanTotalInterest)) * r; //每月还款利息=剩余本金*月利率
								monthlyPayMoney = this.annuity() - monthlyPayInterest; //每月还款利息=每月还款额-每月还款利息
								this.loanTotalInterest += monthlyPayInterest; //累计利息
								this.loanTotalMoney += this.annuity(); //累计还款金额
								let list = {
									month: '第' + (i + 1) + '期',
									monthlyPay: this.annuity().toFixed(2),
									money: monthlyPayMoney.toFixed(2),
									interest: monthlyPayInterest.toFixed(2),
								}
								this.loanTableData.push(list);
							}
						}

					} else if (this.loanMode == "组合贷款") {
						if (this.loanGJJMoney > 0 && this.loanGJJRate > 0) {
							let gjjp = conver.gjjp; //公积金贷款本金
							let gjjr = conver.gjjr; //公积金月利率
							let gjjMonthlyPayInterest = 0; //公积金每月还款利息
							let gjjMonthlyPayMoney = 0; //公积金每月还款本金

							if (this.loanRepayMode == "等额本金") {
								let monthlyPayTotal = 0; //每月月供(还本付息)
								let gjjMonthlyPayTotal = 0; //每月月供(还本付息)

								for (let i = 0; i < n; i++) {
									monthlyPayMoney = p / n; //每月还款本金= 总本金 / 还款月数
									monthlyPayInterest = (p - monthlyPayMoney * i) * r; //每月还款利息=（本金 - 累计已还本金）× 月利率
									monthlyPayTotal = monthlyPayMoney + monthlyPayInterest; //月供 = 每月还款本金 + 每月还款利息

									gjjMonthlyPayMoney = gjjp / n; //公积金每月还款本金
									gjjMonthlyPayInterest = (gjjp - gjjMonthlyPayMoney * i) * gjjr; //公积金每月还款利息
									gjjMonthlyPayTotal = gjjMonthlyPayMoney + gjjMonthlyPayInterest; //公积金月供

									this.loanTotalInterest = this.loanTotalInterest + monthlyPayInterest + gjjMonthlyPayInterest; //累计利息
									this.loanTotalMoney = this.loanTotalMoney + monthlyPayTotal + gjjMonthlyPayTotal; //累计还款金额
									let list = {
										month: '第' + (i + 1) + '期',
										monthlyPay: (monthlyPayTotal + gjjMonthlyPayTotal).toFixed(2),
										money: (monthlyPayMoney + gjjMonthlyPayMoney).toFixed(2),
										interest: (monthlyPayInterest + gjjMonthlyPayInterest).toFixed(2),
									}
									this.loanTableData.push(list);
								}
							} else if (this.loanRepayMode == "等额本息") {
								this.loanMonthlyPay = this.annuity() + this.annuity(true);
								let ti = 0,gjjti = 0;

								for (let i = 0; i < n; i++) {

									monthlyPayInterest = (p - (this.annuity() * i - ti)) * r; //每月还款利息=剩余本金*月利率
									monthlyPayMoney = this.annuity() - monthlyPayInterest; //每月还款利息=每月还款额-每月还款利息
									ti += monthlyPayInterest;

									gjjMonthlyPayInterest = (gjjp - (this.annuity(true) * i - gjjti)) * gjjr; //公积金每月还款利息
									gjjMonthlyPayMoney = this.annuity(true) - gjjMonthlyPayInterest; //公积金每月还款利息
									gjjti += gjjMonthlyPayInterest;

									this.loanTotalInterest = ti + gjjti; //累计利息
									this.loanTotalMoney = this.loanTotalMoney + this.annuity() + this.annuity(true); //累计还款金额
									let list = {
										month: '第' + (i + 1) + '期',
										monthlyPay: (this.annuity() + this.annuity(true)).toFixed(2),
										money: (monthlyPayMoney + gjjMonthlyPayMoney).toFixed(2),
										interest: (monthlyPayInterest + gjjMonthlyPayInterest).toFixed(2),
									}
									this.loanTableData.push(list);
								}
							}
						}
					}
				}
			},
			switchLoanMethod() {
				if (this.loanMode == "商业/公积金贷款") {
					document.getElementById('loanGJJMoney').style.display = "none";
					document.getElementById('loanGJJRate').style.display = "none";
					document.getElementById('loanMoney').children[0].getElementsByTagName('span')[0].textContent = "贷款金额";
					document.getElementById('loanRate').children[0].getElementsByTagName('span')[0].textContent = "贷款利率(%)";
				} else if (this.loanMode == "组合贷款") {
					document.getElementById('loanGJJMoney').style.display = "";
					document.getElementById('loanGJJRate').style.display = "";
					document.getElementById('loanMoney').children[0].getElementsByTagName('span')[0].textContent = "商业贷款金额";
					document.getElementById('loanRate').children[0].getElementsByTagName('span')[0].textContent = "商业贷款利率(%)";
				}
				this.onInput();
			},
			switchRepayMode() {
				if (this.loanRepayMode == "等额本金") {
					document.getElementById('loanMonthlyPay').style.display = "none";
					document.getElementById('loanFirstPay').style.display = "";
				} else if (this.loanRepayMode == "等额本息") {
					document.getElementById('loanMonthlyPay').style.display = "";
					document.getElementById('loanFirstPay').style.display = "none";
				}
				this.onInput();
			},
		},
		mounted() {
			this.switchLoanMethod();
			this.switchRepayMode();
		},
	}
</script>

<style scoped>
  .loans {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.loans table{
		margin: auto;
	}
	.loans table tr td {
		height: 33px;
		text-align: left;
	}

	@media screen and (min-width: 1200px){
		.td1{
			width: 180px;
		}
		.td2{
			width: 200px;
		}
	}
	@media screen and (max-width: 1200px){
		.td1{
			width: 180px;
		}
		.td2{
			width: 200px;
		}
	}
	@media screen and (max-width: 767px){
		.td1{
			width: 146px;
		}
		.td2{
			width: 81px;
			white-space:normal;
		}
	}
	@media screen and (max-width: 405px){
		.td1{
			width: 116px;
		}
		.td2{
			width: 81px;
			white-space:normal;
		}
	}
</style>
