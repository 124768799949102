import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
	state: {
		stockNum: "600036",
	},
	mutations: {
		setStockNum(state, stockNum){
			state.stockNum = stockNum;
		},
	},
	actions: {},
	getters: {
		getStockNum(state){
			return state.stockNum;
		},
	}
}