<template>
    <div class="footer">
        <el-row>
            <el-col :span="6">
                <span class="title">在线工具</span>
                <div class="text-gray"><a href="/">随机密码生成</a></div>
                <div class="text-gray"><a href="/invest/">利息计算器</a></div>
                <div class="text-gray"><a href="/stockcost/">成本计算器</a></div>
                <div class="text-gray"><a href="/changecode/">编码转换</a></div>
            </el-col>
            <el-col :span="6">
                <span class="title"><a href="#">使用教程<i class="el-icon-s-promotion"></i></a></span>
                <div class="text-gray"><a href="https://www.itptg.com/">如何轻松使用贷款计算器？</a></div>
            </el-col>
            <el-col :span="6">
                <span class="title">关于</span>
                <div class="text-gray"><a href="https://www.itptg.com/">联系我们</a></div>
                <div class="text-gray"><a href="https://www.itptg.com/">隐私政策</a></div>
                <div class="text-gray"><a href="https://www.itptg.com/">服务条款</a></div>

            </el-col>
            <el-col :span="6">
                <span class="title">友联</span>
                <div class="text-gray"><a href="https://tools.itptg.com/" target="_blank">IT工具集合站</a></div>
                <div class="text-gray"><a href="https://pdf2docx.cn/" target="_blank">PDF转换器</a></div>
                <div class="text-gray"><a href="https://tool.chinaz.com/" target="_blank">站长工具</a></div>
            </el-col>
        </el-row>
        <p style="margin-top: 30px">
            Copyright © 2020-2025 程序员老魏. All Rights Reserved. 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备18052248号-2</a></p>
        <p><img style="height: 15px;margin: 0;padding: 0" src="@/assets/images/gonganbeian.png"/> <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011402054222" rel="noreferrer" target="_blank">京公网安备11011402054222</a></p>
        <p>联系邮箱：18210493942@163.com</p>
        <p>广告合作、问题或建议反馈联系邮箱：18210493942@163.com</p>
    </div>
</template>

<script>
export default {
    name: "customFooter"
}
</script>

<style scoped>

.footer {
    height: 300px;
}

.footer .title {
    font-weight: 600;
    font-size: 18px;
    color: #333;
    line-height: 30px;
}

.footer .title a {
    color: inherit;
}

.footer .text-gray {
    line-height: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.footer .text-gray.a {
    color: inherit;
    text-decoration: inherit;
}

a {
    color: inherit;
}

a:hover {
    text-decoration: underline;
}
</style>
