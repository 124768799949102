<template>
    <el-container :direction="containerMode">
        <el-header>
            <custom-header ref="customHeader"/>
        </el-header>
        <el-main>
            <router-view/>
        </el-main>
        <el-footer>
            <div class="custom-footer">
                <custom-footer ref="customFooter"/>
            </div>
        </el-footer>
    </el-container>
</template>

<script>
import CustomFooter from '@/components/CustomFooter.vue'
import CustomHeader from '@/components/CustomHeader.vue'
export default {
    components:{
        CustomFooter,
        CustomHeader
    },
    data() {
        return {
            containerMode: 'vertical'
        }
    },
    methods: {

    },
    created() {

    },
    mounted () {

    },
    watch: {

    }
}
</script>

<style scoped>



@media screen and (min-width: 800px){
    .el-header {
        height: 57px !important;
        line-height: 52px;
        font-size: 18px;
        border-bottom: 1px solid #DCDFE6;
    }
    .el-main {
        margin-left: auto;
        margin-right: auto;
        width: 1180px;
        overflow: visible!important;
    }
    .el-footer{
        border-top: 1px solid #DCDFE6;
        padding-top: 20px;
    }
    .custom-footer{
        width: 1180px;
        margin: 0 auto;
    }

}


@media screen and (max-width: 799px) {
    .el-header {
        padding: 0px 0px 0px 0px;
    }
    .menu-title {
        font-size: 20px;
        height: 40px !important;
        line-height: 40px !important;
    }

    .el-menu-main .el-menu-item, .el-menu-main >>> .el-submenu__title {
        height: 40px !important;
        line-height: 40px !important;
        padding: 0 10px 0 8px !important;
    }

    .el-main {
        /*padding: 10px 0px 0 10px;*/
        font-size: 17px;
    }
}
</style>
