<template>
    <div class="header">
        <div class="menu-body-pc">
            <a class="title">便民工具箱</a>
            <el-menu
                    ref="elMenuMain"
                    :default-active="menu.addr"
                    unique-opened
                    router
                    class="el-menu-main"
                    @select="handleSelect"
                    text-color="rgba(9,9,11,0.6)"
                    active-text-color="inherit"
                    background-color="#fff"
                    :mode="menuMode">
                <el-menu-item index="/">随机密码生成</el-menu-item>
                <el-menu-item index="/invest/">利息计算器</el-menu-item>
                <el-menu-item index="/stockcost/">成本计算器</el-menu-item>
                <el-menu-item index="/changecode/">编码转换</el-menu-item>
                <el-menu-item index="/pdftoword/">PDF转Word</el-menu-item>
<!--                <el-menu-item index="/encryption">图片压缩</el-menu-item>-->
<!--                <el-menu-item index="/encryption">二维码生成</el-menu-item>-->
<!--                <el-menu-item index="/encryption">进制转换</el-menu-item>-->
<!--                <el-menu-item index="/encryption">文本对比</el-menu-item>-->
<!--                <el-menu-item index="/encryption">IP地址计算器</el-menu-item>-->
<!--                <el-menu-item index="/encryption">屏幕录制</el-menu-item>-->
<!--                <el-menu-item index="/encryption">在线屏幕监测</el-menu-item>-->
<!--                <el-menu-item index="/encryption">根据台词找影视</el-menu-item>-->
            </el-menu>
        </div>
        <div class="menu-body-mobile">
            <a class="font-bold flex items-center" href="/">便民工具箱</a>
            <i class="el-icon-s-fold menu" @click="openMenu"></i>
        </div>
        <mobile-header ref="mobileHeader"/>
    </div>
</template>

<script>
import  MobileHeader from '@/components/MobileHeader.vue'
export default {
    components:{
        MobileHeader
    },
    name: "customHeader",
    data() {
        return {
            menuMode: 'horizontal',
            menu: {
                title: '贷款计算器',
                index: '1',
                addr: '/'
            },
            layout: 'pc',
            drawer: false,
        }
    },
    created() {
    },
    methods: {
        openMenu(){
            this.$refs.mobileHeader.show();
        },
        handleSelect(key, keyPath) {
            if (key == '/') {
                this.menu.title = "随机密码生成";
                this.menu.index = 1;
            } else if (key == '/invest') {
                this.menu.title = "利息计算器";
                this.menu.index = 1;
            } else if (key == '/stockcost') {
                this.menu.title = "成本计算器";
                this.menu.index = 1;
            } else if (key == '/changecode') {
                this.menu.title = "编码转换";
                this.menu.index = 3;
            } else if (key == '/encryption') {
                this.menu.title = "密钥生成器";
                this.menu.index = 2;
            }
            this.menu.addr = key;
            this.$store.commit("setMenu", this.menu);
        },
    },


}
</script>

<style scoped>


@media screen and (min-width: 800px) {
    .el-menu-main .el-menu-item, .el-menu-main >>> .el-submenu__title {
        font-size: .875rem;
    }

    .menu-body-pc {
        text-align: left;
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .menu-body-pc .title {
        float: left;
        font-weight: 700;
    }

    .menu-body-pc .el-menu {
        float: left;
    }

    .el-menu--horizontal > .el-menu-item.is-active {
        border-bottom: 0px solid #409EFF;
        color: #303133;
    }

    .menu-body-pc .el-menu .el-submenu .el-menu-item {
        min-width: unset;
    }

    .el-menu--horizontal > .el-menu-item {
        float: left;
        height: 53px;
        line-height: 53px;
        margin: 0;
        border-bottom: 2px solid transparent;
        color: #909399;
    }

    .el-menu.el-menu--horizontal {
        border-bottom: 0px solid #DCDFE6;
    }

    .menu-body-mobile {
        display: none;
    }
    .el-submenu__title:hover,
    .el-submenu.is-active,
    .el-menu-item.is-active {
        background-image: linear-gradient(to bottom, #ffffff, #ffffff);
        background-color: transparent;
        border-bottom-color: #ffffff !important;
        border-bottom: 0px solid #ffffff;
    }

    /*鼠标悬上样式*/
    .el-menu-item:not(.is-disabled):hover,
    .el-menu-item:not(.is-disabled):focus {
        background-image: linear-gradient(to bottom, #ffffff, #ffffff);
        /*border-bottom-color: #ffffff !important;*/
        border: 0;
    }
}


@media screen and (max-width: 799px) {
    .menu-body-pc {
        display: none;
        margin: 0;
        padding: 0;
    }

    .menu-body-mobile {
        text-align: left;
        height: 56px;
        line-height: 56px;
    }
    .menu-body-mobile a{
        font-weight: 700;
        font-size: 18px;
        padding: 10px;
        color: initial;
    }

    .menu-body-mobile .title {
        float: left;
        font-weight: 700;
    }
    .menu-body-mobile .menu{
        font-size: 25px;
        float: right;
        line-height: 56px;
        margin-right: 20px;
        color: initial;
    }
}
</style>
